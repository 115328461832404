// Generated automatically by nearley, version 2.16.0
// http://github.com/Hardmath123/nearley
(function () {
function id(x) { return x[0]; }

  const lexer = require('./lexer')
var grammar = {
    Lexer: lexer,
    ParserRules: [
    {"name": "block", "symbols": ["line"], "postprocess": 
        // both block and brackets will be arrays as in
        // [ [ blockChild1, blockChild2 ], line ]
        ([line]) => {
          return {
            type: 'block',
            children: [ ...line ]
          }
        }
              },
    {"name": "block$subexpression$1", "symbols": ["frontMatter"]},
    {"name": "block$subexpression$1", "symbols": ["ctrlConditional"]},
    {"name": "block$subexpression$1", "symbols": ["ctrlForKey"]},
    {"name": "block$subexpression$1", "symbols": ["ctrlFor"]},
    {"name": "block", "symbols": ["block$subexpression$1"], "postprocess": 
        // both block and brackets will be arrays as in
        // [ [ blockChild1, blockChild2 ], line ]
        ([content]) => {
          return {
            type: 'block',
            children: [ ...content ]
          }
        }
              },
    {"name": "block", "symbols": ["block", "line"], "postprocess": 
        ([block, line]) => {
          block.children.push(...line)
          return block
        }
              },
    {"name": "block$subexpression$2", "symbols": ["ctrlConditional"]},
    {"name": "block$subexpression$2", "symbols": ["ctrlForKey"]},
    {"name": "block$subexpression$2", "symbols": ["ctrlFor"]},
    {"name": "block", "symbols": ["block", "block$subexpression$2"], "postprocess": 
        // both block and brackets will be arrays as in
        // [ [ blockChild1, blockChild2 ], line ]
        ([block, addition]) => {
          block.children.push(...addition)
          return block
        }
              },
    {"name": "line$ebnf$1", "symbols": []},
    {"name": "line$ebnf$1$subexpression$1", "symbols": [(lexer.has("content") ? {type: "content"} : content)]},
    {"name": "line$ebnf$1$subexpression$1", "symbols": [(lexer.has("interpolation") ? {type: "interpolation"} : interpolation)]},
    {"name": "line$ebnf$1", "symbols": ["line$ebnf$1", "line$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "line", "symbols": ["line$ebnf$1", (lexer.has("lb") ? {type: "lb"} : lb)], "postprocess": 
        // the brackets above return an array as in
        // [ [ [ content1 ], [ content2 ], [ interpolation1 ] ], lb ]
        // the whole thing needs to be flattened
        ([ contentsAndInterpolations, lb ]) => {
          return [...contentsAndInterpolations.map(([i]) => i), lb ]
        }
              },
    {"name": "frontMatter", "symbols": [(lexer.has("frontMatter") ? {type: "frontMatter"} : frontMatter), (lexer.has("lb") ? {type: "lb"} : lb), "block", (lexer.has("frontMatter") ? {type: "frontMatter"} : frontMatter), (lexer.has("lb") ? {type: "lb"} : lb)], "postprocess": 
        ([frontMatter, lb, block, frontMatter2, lb2]) => {
          return {
            type: 'frontMatter',
            block
          }
          // return [frontMatter, lb, block.flat(1), frontMatter2, lb2]
        }
              },
    {"name": "ctrlConditional$ebnf$1", "symbols": []},
    {"name": "ctrlConditional$ebnf$1$subexpression$1", "symbols": ["ctrlElseIf", "block"]},
    {"name": "ctrlConditional$ebnf$1", "symbols": ["ctrlConditional$ebnf$1", "ctrlConditional$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "ctrlConditional$ebnf$2$subexpression$1", "symbols": ["ctrlElse", "block"]},
    {"name": "ctrlConditional$ebnf$2", "symbols": ["ctrlConditional$ebnf$2$subexpression$1"], "postprocess": id},
    {"name": "ctrlConditional$ebnf$2", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "ctrlConditional", "symbols": ["ctrlIf", "block", "ctrlConditional$ebnf$1", "ctrlConditional$ebnf$2", "ctrlClose"], "postprocess": 
        ([ctrlIf, block, ctrlElseIfBlocks, ctrlElseBlock, ctrlClose]) => {
          const ctrlStructure = []
          ctrlStructure.push({
            type: 'ctrlIf',
            op: ctrlIf[1],
            block
          })
          if (ctrlElseIfBlocks) {
            ctrlElseIfBlocks.forEach((ctrlElseIfBlock) => {
              // console.log(ctrlElseIfBlock)
              ctrlStructure.push({
                type: 'ctrlElseIf',
                op: ctrlElseIfBlock[0][1],
                block: ctrlElseIfBlock[1]
              })
            })
          }
          if (ctrlElseBlock) {
            // console.log(ctrlElseBlock)
            ctrlStructure.push({
              type: 'ctrlElse',
              block: ctrlElseBlock[1]
            })
          }
          return {
            type: 'ctrlConditional',
            ctrlStructure,
          }
        }
              },
    {"name": "ctrlIf", "symbols": [(lexer.has("ctrlIf") ? {type: "ctrlIf"} : ctrlIf), (lexer.has("ctrlIfOp") ? {type: "ctrlIfOp"} : ctrlIfOp), (lexer.has("lb") ? {type: "lb"} : lb)]},
    {"name": "ctrlElseIf", "symbols": [(lexer.has("ctrlElseIf") ? {type: "ctrlElseIf"} : ctrlElseIf), (lexer.has("ctrlIfOp") ? {type: "ctrlIfOp"} : ctrlIfOp), (lexer.has("lb") ? {type: "lb"} : lb)]},
    {"name": "ctrlElse", "symbols": [(lexer.has("ctrlElse") ? {type: "ctrlElse"} : ctrlElse), (lexer.has("lb") ? {type: "lb"} : lb)]},
    {"name": "ctrlClose", "symbols": [(lexer.has("ctrlClose") ? {type: "ctrlClose"} : ctrlClose), (lexer.has("lb") ? {type: "lb"} : lb)]},
    {"name": "ctrlForKey", "symbols": [(lexer.has("ctrlFor") ? {type: "ctrlFor"} : ctrlFor), (lexer.has("ctrlForKeyOp") ? {type: "ctrlForKeyOp"} : ctrlForKeyOp), (lexer.has("lb") ? {type: "lb"} : lb), "block", (lexer.has("ctrlClose") ? {type: "ctrlClose"} : ctrlClose), (lexer.has("lb") ? {type: "lb"} : lb)], "postprocess": 
        ([ctrlFor, ctrlForKeyOp, lb, block, ctrlClose, lb2]) => {
          return {
            type: 'ctrlForKey',
            op: ctrlForKeyOp,
            block
          }
        }
              },
    {"name": "ctrlFor", "symbols": [(lexer.has("ctrlFor") ? {type: "ctrlFor"} : ctrlFor), (lexer.has("ctrlForOp") ? {type: "ctrlForOp"} : ctrlForOp), (lexer.has("lb") ? {type: "lb"} : lb), "block", (lexer.has("ctrlClose") ? {type: "ctrlClose"} : ctrlClose), (lexer.has("lb") ? {type: "lb"} : lb)], "postprocess": 
        ([ctrlFor, ctrlForOp, lb, block, ctrlClose, lb2]) => {
          return {
            type: 'ctrlFor',
            op: ctrlForOp,
            block
          }
        }
              }
]
  , ParserStart: "block"
}
if (typeof module !== 'undefined'&& typeof module.exports !== 'undefined') {
   module.exports = grammar;
} else {
   window.grammar = grammar;
}
})();
