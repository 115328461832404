const _marked = require('marked')

// set up marked renderer
const renderer = new _marked.Renderer()
/* eslint-disable func-names */
renderer.table = function (header, body) {
  const tableStyles = `
    padding: 0;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1em;
    font: inherit;
    border: 0;
  `
  const bodyStyles = `
    margin: 0;
    padding: 0;
    border: 0;
  `
  return `
      <table style="${tableStyles}">
        <thead>${header}</thead>
        <tbody style="${bodyStyles}">${body}</tbody>
      </table>
    `
}
renderer.tablerow = function (content) {
  const rowStyles = `
    border: 0;
    border-top: 1px solid #CCC;
    background-color: white;
    margin: 0;
    padding: 0;
  `
  return `
    <tr style="${rowStyles}">${content}</tr>
  `
}
renderer.tablecell = function (content, flags) {
  const headCellStyles = `
    font-size: 1em;
    border: 1px solid #CCC;
    margin: 0;
    padding: 0.5em 1em;
    font-weight: bold;
    background-color: #F0F0F0;
  `
  const bodyCellStyles = `
    font-size: 1em;
    border: 1px solid #CCC;
    margin: 0;
    padding: 0.5em 1em;
  `
  if (flags.header) {
    return `
      <td style="${headCellStyles}">${content}</td>
    `
  }
  return `
    <td style="${bodyCellStyles}">${content}</td>
  `
}

function marked (raw) {
  return _marked(raw, { renderer })
}

module.exports = {
  marked
}
