const { default: Footer } = require('../Footer')
const { default: Nav } = require('../Nav')

const App = {
  components: {
    Nav,
    Footer
  }
}

module.exports = App
