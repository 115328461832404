/* eslint-disable max-classes-per-file */
const hjson = require('hjson/bundle/hjson')
const jexl = require('jexl')

const defaults = {
  trim: true
}

class Err extends Error {
  constructor (message, token) {
    super(Array.isArray(message) ? message.join('\n') : message)
    this.token = token
  }
}
class RenderError extends Error {
  constructor (message, detail) {
    super(message)
    const code = 'RENDER_ERROR'
    let {
      lines
    } = detail
    // debugger
    if (!lines) {
      const match = /line (\d{1,3}),/.exec(message)
      if (!match) {
        lines = []
      } else {
        const line = parseInt(match[1], 10)
        lines = [line - 1, line]
      }
    }
    this.code = code
    this.detail = {
      message,
      code,
      ...detail,
      lines
    }
    // console.log('this', this)
  }
}

function render (item, locals = defaults) {
  const { type } = item

  // recurse
  if (type === 'block') {
    let output = item.children.map((i) => render(i, locals))
    output = output.join('')
    if (locals.trim)
      output = output.replace(/^ +(?!-)/gm, '')
    return output
  }

  if (type === 'frontMatter')
    return renderFrontMatter(item, locals)
  if (type === 'ctrlConditional')
    return renderConditional(item, locals)
  if (type === 'ctrlFor')
    return renderFor(item, locals)
  if (type === 'ctrlForKey')
    return renderForKey(item, locals)
  if (type === 'interpolation')
    return renderInterpolation(item, locals)
  return renderContent(item)
}

/* eslint-enable no-use-before-define */

function renderFrontMatter ({ block }, locals) {
  let frontMatter = render(block)
  try {
    frontMatter = hjson.parse(frontMatter)
  } catch (error) {
    const {
      message
    } = error
    const title = 'Bad Front Matter'
    throw new RenderError(message, { title })
  }
  Object.assign(
    locals,
    frontMatter
  )
  return ''
}

function renderConditional ({ ctrlStructure }, locals) {
  // find an operator that evaluates to true
  const passed = ctrlStructure.findIndex((ctrl) => {
    // ctrlElse is always last, and it always passes
    if (ctrl.type === 'ctrlElse')
      return true

    try {
      return jexl.evalSync(ctrl.op.value, locals)
    } catch ({ message }) {
      const title = 'Bad \'if\' Condition'
      // eslint-disable-next-line no-ex-assign
      message = `${message} in "${ctrl.op.value}" on line ${ctrl.op.line}`
      const lines = [ctrl.op.line - 1] // line is always out by +1
      throw new RenderError(message, { title, lines })
    }
  })

  // if nothing is true, and no else clause, return
  if (passed === -1)
    return ''

  return render(ctrlStructure[passed].block, locals)
}

function renderForKey ({ op, block }, locals) {
  let out = ''
  let collection
  try {
    collection = jexl.evalSync(op.value.collection, locals)
    if (typeof collection === 'undefined')
      throw new Error(`"${op.value.collection}" is not defined`)
    if (typeof collection !== 'object')
      throw new Error(`"${op.value.collection}" is not a collection`)
  } catch (error) {
    let { message } = error
    const title = 'Bad \'for\' Expression'
    message = [
      'Bad \'for\' Expression',
      message,
      `in "${op.text}"`,
      `on line ${op.line}`
    ].join(', ')
    const lines = [op.line]
    throw new RenderError(message, { title, lines })
  }
  Object.keys(collection).forEach((key) => {
    const itemLocals = {
      [op.value.iterator]: collection[key],
      [op.value.key]: key,
      ...locals
    }
    // console.dir(block[3], { depth: null, breakLength: 80 })
    out += render(block, itemLocals)
  })
  return out
}

function renderFor ({ op, block }, locals) {
  let out = ''
  let collection
  try {
    collection = jexl.evalSync(op.value.collection, locals)
    if (collection === undefined)
      throw new Error(`"${op.value.collection}" is not defined`)
    if (typeof collection !== 'object')
      throw new Error(`"${op.value.collection}" is not a collection`)
  } catch (error) {
    let { message } = error
    const title = 'Bad \'for\' Expression'
    message = [
      'Bad \'for\' Expression',
      message,
      `in "${op.text}"`,
      `on line ${op.line}`
    ].join(', ')
    const lines = [op.line]
    throw new RenderError(message, { title, lines })
  }

  Object.values(collection).forEach((item) => {
    const itemLocals = {
      [op.value.iterator]: item,
      ...locals
    }
    // console.dir(block[3], { depth: null, breakLength: 80 })
    out += render(block, itemLocals)
  })
  return out
}

function renderContent ({ text }) {
  return text
}

function renderInterpolation (interpolation, locals) {
  const { value, line } = interpolation
  let interpolated
  try {
    interpolated = jexl.evalSync(value, locals)
    if (interpolated === undefined)
      throw new Error(`"${value}" is not defined`)
  } catch (error) {
    let { message } = error
    const title = 'Bad Interpolation'
    message = [
      'Bad Interpolation',
      message,
      `in "${interpolation.text}"`,
      `on line ${line}`
    ].join(', ')
    const lines = [line - 1] // line is always out by +1
    throw new RenderError(message, { title, lines })
  }
  return interpolated
}

module.exports = {
  render
}
