const {
  IconCheckSquare,
  IconSquare,
  IconZap
} = require('../../icons')

const Controls = {
  components: {
    IconCheckSquare,
    IconSquare,
    IconZap
  },
  data () {
    const localTrim = this.trim
    const localMarkdown = this.markdown
    return {
      localTrim,
      localMarkdown
    }
  },
  methods: {
    clickRender () {
      this.$emit('trim', this.localTrim)
      this.$emit('markdown', this.localMarkdown)
      const customEvent = new CustomEvent('RENDER')
      document.dispatchEvent(customEvent)
    }
  },
  props: {
    trim: {
      required: true,
      type: Boolean
    },
    markdown: {
      required: true,
      type: Boolean
    }
  }
}

module.exports = Controls
