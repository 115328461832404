/* eslint-disable no-return-assign */
const Error = {
  mounted () {
    document.addEventListener('RENDER_ERROR', (error) => this.error = error)
    document.addEventListener('RENDER_OK', () => this.error = false)
  },
  data () {
    const error = false
    return {
      error
    }
  },
  methods: {
  },
  props: {
  }
}

module.exports = Error
