import { render, staticRenderFns } from "./PageTextExpander.pug?vue&type=template&id=201881aa&scoped=true&lang=pug&"
import script from "./PageTextExpander.js?vue&type=script&lang=js&"
export * from "./PageTextExpander.js?vue&type=script&lang=js&"
import style0 from "./PageTextExpander.less?vue&type=style&index=0&id=201881aa&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "201881aa",
  null
  
)

export default component.exports