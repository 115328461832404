const { default: Controls } = require('../../components/Controls')
const { default: Result } = require('../../components/Result')
const { default: Template } = require('../../components/Template')
const { default: Error } = require('../../components/Error')
const { default: initialTemplate } = require('../../lib/initialTemplate.txt')

const PageTextExpander = {
  data () {
    const markdown = true
    const template = initialTemplate
    const trim = true
    return {
      markdown,
      template,
      trim,
    }
  },
  components: {
    Controls,
    Error,
    Result,
    Template,
  }
}

module.exports = PageTextExpander
