const nearley = require('nearley')
const grammar = require('../../nearley/grammar')

function parse (template) {
  if (!/\n$/.test(template))
    template = template.concat('\n')
  const parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammar))

  parser.feed(template)

  // console.dir(parser.results, { depth: null, breakLength: 1 })
  if (parser.results.length !== 1)
    throw new Error('maybe ambiguous grammar')

  return parser.results[0]
}

module.exports = {
  parse
}
