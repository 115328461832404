const { parse } = require('./parse')
const { render } = require('./render')
const { marked } = require('./marked')
const {
  IconFileText
} = require('../../icons')

const Result = {
  created () {
    document.addEventListener('RENDER', () => {
      // next tick is necessary or else trim & markdown props won't have
      // updated already
      this.$nextTick(() => this.renderTemplate())
    })
    this.renderTemplate()
  },
  components: {
    IconFileText
  },
  data () {
    const result = ''
    return {
      result
    }
  },
  methods: {
    renderTemplate () {
      let parsed
      let result
      try {
        parsed = parse(this.template)
        result = render(parsed)
      } catch ({ detail }) {
        const customEvent = new CustomEvent(detail.code, { detail })
        document.dispatchEvent(customEvent)
        return
      }
      const customEvent = new CustomEvent('RENDER_OK')
      document.dispatchEvent(customEvent)
      if (this.markdown)
        result = marked(result)
      this.result = result
    },
    clickCopy () {
      const container = this.$el.querySelector('.result-display')
      const first = container.childNodes[0]
      const last = container.childNodes[container.childNodes.length - 1]
      const range = document.createRange()
      range.setStartBefore(first)
      range.setEndAfter(last)
      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
    }
  },
  props: {
    template: {
      type: String,
      required: true
    },
    markdown: {
      type: Boolean,
      required: true,
    },
    trim: {
      type: Boolean,
      required: true
    }
  }
}

module.exports = Result
