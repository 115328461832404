import { render, staticRenderFns } from "./App.pug?vue&type=template&id=284669fb&scoped=true&lang=pug&"
import script from "./App.js?vue&type=script&lang=js&"
export * from "./App.js?vue&type=script&lang=js&"
import style0 from "../../styles/_global.less?vue&type=style&index=0&prod&lang=less&"
import style1 from "./App.less?vue&type=style&index=1&id=284669fb&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "284669fb",
  null
  
)

export default component.exports