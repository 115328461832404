const {
  IconHeart,
  IconTwitter
} = require('../../icons')

const Footer = {
  components: {
    IconHeart,
    IconTwitter
  }
}

module.exports = Footer
