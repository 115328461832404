/* eslint-disable no-return-assign */
const Template = {
  mounted () {
    document.addEventListener('RENDER_ERROR', ({ detail }) => {
      this.errorLines = detail.lines
    })
    document.addEventListener('RENDER_OK', () => this.errorLines = [])
    this.$nextTick(function mounted () {
      const templateEditor = this.$el.querySelector('.template-editor')
      templateEditor.style.height = ''
      templateEditor.style.height = `${templateEditor.scrollHeight}px`
    })
  },
  data () {
    const templateCopy = this.template
    const newline = /\n/
    const errorLines = []
    return {
      templateCopy,
      errorLines,
      newline
    }
  },
  methods: {
    // errorHandler (error) {
    //   console.log('handler', err)
    // },
    inputTemplateEditor (event) {
      this.templateCopy = event.target.value
      event.target.style.height = ''
      event.target.style.height = `${event.target.scrollHeight}px`
    }
  },
  props: {
    template: {
      type: String,
      required: true
    }
  }
}

module.exports = Template
