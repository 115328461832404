/** lexer
 * the lexer is fairly self explanatory. moo is going to use the regexps
 * defined here to create a list of tokens from the template passed in
 * for some tokens you're simply trying to identify or recognise something
 * for others you can use the value method to modify the value of the token
 * in some way
 */

const moo = require('moo')

module.exports = moo.compile({
  lb: {
    match: /(?:\r\n|\n)/,
    lineBreaks: true
  },
  frontMatter: {
    match: /---+/
  },
  ctrlIf: {
    match: / *:: *if */
  },
  ctrlElseIf: {
    match: / *:: *} *else if */
  },
  ctrlIfOp: {
    match: /\((?!.* in .*).+\) *{ */,
    value: (i) => i.match(/\((.+)\)/)[1]
  },
  ctrlElse: {
    match: / *:: *} *else *{ */
  },
  ctrlFor: {
    match: / *:: *for */
  },
  ctrlForKeyOp: {
    match: /\([a-zA-Z]+, *[a-zA-Z]+ *in *.+\) *{ */,
    value: (i) => {
      const match = i.match(/\(([a-zA-Z]+), *([a-zA-Z]+) *in *(.+)\) \{ */)
      return {
        iterator: match[1],
        key: match[2],
        collection: match[3]
      }
    }
  },
  ctrlForOp: {
    match: /\([a-zA-Z]+ *in *.+\) *{ */,
    value: (i) => {
      const match = i.match(/\(([a-zA-Z]+) *in *(.+)\) \{ */)
      return {
        iterator: match[1],
        collection: match[2]
      }
    }
  },
  ctrlClose: {
    match: / *:: *} */
  },
  interpolation: {
    match: /\$\{[^}]*\}/,
    value: (i) => i.slice(2, -1)
  },
  content: {
    match: /(?:(?!\$\{).)+/
  }
})
