const {
  IconCode
} = require('../../icons')

const Nav = {
  components: {
    IconCode
  }
}

module.exports = Nav
