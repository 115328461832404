const marked = require('marked')
const { default: referenceRaw } = require('../../lib/reference.md')

const PageIndex = {
  data () {
    const reference = marked(referenceRaw)
    return {
      reference
    }
  }
}

module.exports = PageIndex
