const { default: Vue } = require('vue')
const { default: VueRouter } = require('vue-router')
const { default: App } = require('./components/App')
const {
  PageIndex,
  PageTextExpander
} = require('./pages')

const routes = [
  {
    path: '/',
    name: 'index',
    component: PageIndex
  },
  {
    path: '/textExpander',
    name: 'text-expander',
    component: PageTextExpander
  }
]

const router = new VueRouter({ routes })

Vue.use(VueRouter)

new Vue({
  render (h) { return h(App) },
  router,
  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
