// see icons at https://feathericons.com/
// get them from https://github.com/feathericons/feather/tree/master/icons

const { default: IconCheckSquare } = require('./IconCheckSquare')
const { default: IconFileText } = require('./IconFileText')
const { default: IconMeh } = require('./IconMeh')
const { default: IconSquare } = require('./IconSquare')
const { default: IconZap } = require('./IconZap')
const { default: IconCode } = require('./IconCode')
const { default: IconHeart } = require('./IconHeart')
const { default: IconTwitter } = require('./IconTwitter')

module.exports = {
  IconCode,
  IconCheckSquare,
  IconFileText,
  IconMeh,
  IconSquare,
  IconZap,
  IconHeart,
  IconTwitter
}
